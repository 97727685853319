<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="d-flex align-items-center ml-3">
          <div
            class="z-icon-wrapper border cursor-pointer"
            @click="$router.push('/triedy')"
          >
            <svg-arrow-left-icon />
          </div>
          <div v-if="group" class="d-flex align-items-center">
            <!-- <svg-google-classroom-icon class="z-group-title-icon ml-2 mb-0" /> -->
            <svg-school-colored-icon class="z-group-title-icon ml-2 mb-0" />
            <h1 class="ml-2 mb-0" v-if="!editMode">
              {{ group.attributes.name }}
            </h1>
            <input
              class="ml-2 mb-0"
              v-if="group.attributes.name && editMode"
              :style="'width:' + (group.attributes.name.length + 1) + 'ch;'"
              v-model="editedGroup.name"
            />
            <h1 ref="subject-input" class="ml-2 mb-0" v-if="!editMode">
              {{ group.attributes.subject }}
            </h1>
            <input
              class="ml-2 mb-0"
              v-if="group.attributes.subject && editMode"
              :style="'width:' + (group.attributes.subject.length + 1) + 'ch;'"
              v-model="editedGroup.subject"
            />
            <b-button
              class="edit-btn ml-2 pt-1 pb-1 pr-2 pl-2"
              variant="outline-secondary"
              v-if="!editMode && isAdmin"
              @click="editMode = true"
              >Upraviť
            </b-button>
            <b-button
              class="edit-btn mr-2 pt-1 pb-1 pr-2 pl-2"
              variant="outline-secondary"
              v-if="editMode"
              @click="editGroup(), (editMode = false)"
              >Uložiť
            </b-button>
            <b-button
              class="edit-btn pt-1 pb-1 pr-2 pl-2"
              variant="outline-secondary"
              v-if="editMode"
              @click="
                (editMode = false),
                  (editedGroup.name = group.attributes.name),
                  (editedGroup.subject = group.attributes.subject)
              "
              >Zrušiť
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="text-right pt-3">
          <b-button
            type="button"
            variant="outline-primary"
            v-if="tabIndex === 0 && isAdmin && academicYearActive"
            @click="createInvitationLink()"
            ><svg-share-icon /> Pridať študentov</b-button
          >
          <b-button
            type="button"
            variant="outline-primary"
            v-else-if="tabIndex === 1 && isAdmin && academicYearActive"
            @click="$refs['create-task-modal'].showModal()"
            >+ Zadať úlohu</b-button
          >
        </div>
      </b-col>
    </b-row>
    <!-- <b-row
      v-if="isAdmin"
      class="d-flex align-items-center"
      style="color: var(--a-color-secondary); font-size: 14px"
    >
      <svg-icon-help-circle class="mr-2" />
      <span class="font-weight-bold">
        NEZABUDNITE aktualizovať názvy tried v novom školskom roku. Kliknite na
        "Upraviť".
      </span>
    </b-row> -->

    <b-row v-if="isAdmin" class="mt-3 mb-3">
      <b-col class="cell-border mt-3 mr-3 pb-2">
        <p class="mb-2 font-small">Dátum vytvorenia triedy</p>
        <b v-if="group" class="font-small">{{
          formatDate(group.attributes.createdAt)
        }}</b>
      </b-col>
      <b-col class="cell-border mt-3 mr-3 pb-2">
        <p class="mb-2 font-small">Dátum poslednej zmeny triedy</p>
        <b v-if="group" class="font-small">{{
          formatDate(group.attributes.updatedAt)
        }}</b>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs v-model="tabIndex">
          <b-col>
            <b-tab
              title="Zoznam študentov"
              active
              class="mb-5 mt-4"
              v-if="isAdmin"
              @click="
                Number($route.query.tabIndex) != 0 &&
                  $router.push({ query: { tabIndex: 0 } })
              "
            >
              <div
                v-if="showInvitedMembers"
                class="d-flex align-items-center mt-4 mb-4"
              >
                <div class="z-icon-wrapper border">
                  <svg-gc-invited-student-icon />
                </div>
                <div>
                  <h1 class="ml-4 mb-0">
                    Žiadosti o pridanie ({{ invitedMembers.length }})
                  </h1>
                </div>
              </div>
              <GroupTable
                v-if="showInvitedMembers"
                class="invitational-table"
                :tableItems="invitedMembers"
                :tableFields="invitedMemberFields"
                :tableHeader="'d-none'"
                @studentConfirmation="studentConfirmation"
                @studentToRemove="studentToRemove"
              />
              <div
                v-if="showActiveMembersTitle"
                class="d-flex align-items-center mt-4 mb-4"
              >
                <div class="z-icon-wrapper border">
                  <svg-gc-active-student-icon />
                </div>
                <div>
                  <h1 class="ml-4 mb-0" v-if="activeMembers">
                    Zoznam študentov ({{ activeMembers.length }})
                  </h1>
                </div>
              </div>
              <GroupTable
                v-if="activeMembers"
                class="--clickable-rows"
                :tableItems="activeMembers"
                :tableFields="activeMembersFields"
                @studentToRemove="studentToRemove"
                @rowClicked="navigateToMember"
              />
              <div
                v-if="deletedMembers && deletedMembers.length"
                class="d-flex align-items-center mt-4 mb-4"
              >
                <div class="z-icon-wrapper border">
                  <svg-gc-removed-student-icon />
                </div>
                <div>
                  <h1 class="ml-4 mb-0">
                    Odstránení študenti ({{ deletedMembers.length }})
                  </h1>
                </div>
              </div>
              <GroupTable
                v-if="deletedMembers && deletedMembers.length"
                :tableItems="deletedMembers"
                :tableFields="deletedMembersFields"
                :tableHeader="'d-none'"
                @itemToReactivate="reactivate"
                @studentToRemove="studentToRemove"
              />
            </b-tab>
            <b-tab
              title="Úlohy"
              @click="
                Number($route.query.tabIndex) != 1 &&
                  $router.push({ query: { tabIndex: 1 } })
              "
            >
              <Tasks ref="tasks" />
            </b-tab>
          </b-col>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row> </b-row>
    <InvitationalModal ref="invitational-modal" v-if="group" />
    <RemoveModal
      ref="remove-student-modal"
      v-if="group"
      header-text="remove-profile-confirm"
      @remove="removeStudentFromGroup"
    >
      <p>
        {{ $t("remove-confirm") }}
        <b class="fw-bold">
          {{ studentFullName }}
        </b>
        {{ $t("from-group") }}
        <b class="fw-bold">
          {{ group.attributes.name }} {{ group.attributes.subject }}?
        </b>
      </p>
    </RemoveModal>
    <TaskCreateModal
      ref="create-task-modal"
      :courses="courses"
      @createTask="createTask"
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import axios from "/utils/axios";
import { sortByStringProperty } from "/utils/shared.ts";

import * as Sentry from "@sentry/vue";

const api = {
  getCourses: () => {
    return axios.get("courses", {
      params: {
        populate: ["thumbnail", "quiz.form"],
      },
    });
  },
  getGroup: (gid) => {
    return axios.getAuth(`groups/${gid}`, {
      params: {
        populate: [
          "members.user",
          "members.taskResponses.task",
          "creator",
          "academicYear",
        ],
      },
    });
  },
  acceptMember: (mid) => axios.postAuth(`group-invite/${mid}/accept`),
  rejectMember: (gid, mid) => axios.postAuth(`group-invite/${mid}/decline`),
  reactivateMember: (memberId) => {
    return axios.putAuth(`group-members/${memberId}`, {
      data: {
        deletedAt: null,
      },
    });
  },
  removeStudentFromGroup: (memberId) => {
    return axios.deleteAuth(`group-members/${memberId}`);
  },

  editGroup: (groupId, group) => {
    const data = { data: group };
    return axios.putAuth(`groups/${groupId}`, data);
  },
  createTask: (groupId, task) => {
    return axios.postAuth("tasks", {
      data: {
        ...task,
        group: {
          connect: [{ id: groupId }],
        },
      },
    });
  },
};

export default {
  layout: "profile",

  components: {
    Tasks: () => import("/components/Tasks.vue"),
    GroupTable: () => import("/components/Group/GroupTable.vue"),
    RemoveModal: () => import("/components/RemoveModal.vue"),
    TaskCreateModal: () => import("/components/Task/TaskCreateModal.vue"),
    InvitationalModal: () => import("/components/InvitationalModal.vue"),
    "svg-arrow-left-icon": () => import("/assets/icons/arrowLeft.svg?inline"),
    "svg-share-icon": () => import("/assets/icons/share.svg?inline"),
    // 'svg-light-bulb-icon': () => import('/assets/icons/lightBulb.svg?inline'),
    "svg-gc-active-student-icon": () =>
      import("/assets/icons/gcActiveStudent.svg?inline"),
    "svg-gc-invited-student-icon": () =>
      import("/assets/icons/gcInvitedStudent.svg?inline"),
    "svg-gc-removed-student-icon": () =>
      import("/assets/icons/gcRemovedStudent.svg?inline"),
    // 'svg-google-classroom-icon': () => import('/assets/icons/googleClassroom.svg?inline'),
    "svg-school-colored-icon": () =>
      import("/assets/icons/schoolColored.svg?inline"),
    // "svg-icon-help-circle": () =>
    //   import("/assets/icons/helpCircle.svg?inline"),
  },

  data() {
    return {
      activeMembersFields: [
        { key: "index", label: "#" },
        { key: "student_name", label: "Meno", class: "name" },
        { key: "email", label: "E-mail", class: "email" },
        { key: "active_tasks", label: "Počet vypracovaných zadaní" },
        { key: "watch_member", label: "", class: "align-end" },
        { key: "remove", label: "", class: "cancel" },
      ],
      invitedMemberFields: [
        { key: "student_name", label: "Meno", class: "name white" },
        { key: "email", label: "E-mail", class: "email white" },
        { key: "accept", label: "" },
      ],
      deletedMembersFields: [
        { key: "student_name", label: "", class: "name low-opacity" },
        { key: "email", label: "", class: "email low-opacity" },
        { key: "is_removed", label: "", class: "align-end" },
      ],
      group: null,
      courses: null,
      activeMembers: null,
      invitedMembers: null,
      deletedMembers: null,
      invLink: null,
      tabIndex: 0,
      editMode: false,
      editedGroup: {
        name: "",
        subject: "",
      },
      studentFullName: "",
      academicYearActive: false,
    };
  },

  async created() {
    await this._loadGroup();
    await this._loadCourses();
  },

  watch: {
    "$route.query": {
      handler(query) {
        this.tabIndex = Number(query.tabIndex);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    showInvitedMembers() {
      return this.invitedMembers && this.invitedMembers.length;
    },
    showActiveMembersTitle() {
      // NOTE: Show title for active members section only, if section above it is displayed
      return this.activeMembers && this.showInvitedMembers;
    },
  },

  methods: {
    studentToRemove(student) {
      const studentUserData = student.attributes.user.data.attributes;
      this.studentFullName = `${studentUserData.firstName} ${studentUserData.lastName}`;
      this.$refs["remove-student-modal"].showModal(student);
    },
    async navigateToMember(item, index, event) {
      this.$router.push(`${this.$route.path}/${item.id}`);
    },
    async editGroup() {
      this.$toast.clear();
      try {
        await api.editGroup(this.$route.params.id, this.editedGroup);
        this.$toast.success("Trieda bola úspešne upravená");
        this.$nextTick(async () => {
          await this._loadGroup();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async reactivate(id) {
      this.$toast.clear();
      try {
        await api.reactivateMember(id);

        this.$toast.success("Študent/ka bol/a úspešne reaktivovaný/á");
        this.$nextTick(async () => {
          await this._loadGroup();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async removeStudentFromGroup(itemToRemove) {
      this.$toast.clear();
      try {
        await api.removeStudentFromGroup(itemToRemove.id);

        this.$toast.success("Študent/ka bol/a úspešne odstránený/á");
        this.$nextTick(async () => {
          await this._loadGroup();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async createInvitationLink() {
      //http://www.zmudri.sk/triedy?hash=${this.group.invite_hash}
      this.$toast.clear();
      try {
        // const r = await api.fetchHash(this.$route.params.id, {
        // 	"expires_at": moment().add(7, 'days').format('YYYY-MM-DD HH:MM:SS').toString()
        // })
        const invitational_link = `${window.location.protocol}//${window.location.host}/triedy?hash=${this.group.attributes.inviteHash}`;
        this.$refs["invitational-modal"].showModal(invitational_link);
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async createTask(task) {
      this.$toast.clear();

      try {
        const { courseId, ...data } = task;

        await api.createTask(this.$route.params.id, {
          ...data,
          course: courseId,
        });

        this.$nextTick(async () => {
          await this.$refs["tasks"]._loadTasks();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async studentConfirmation(e) {
      this.$toast.clear();
      try {
        e.confirmed
          ? await api.acceptMember(e.id)
          : await api.rejectMember(this.$route.params.id, e.id);
        this.$nextTick(async () => {
          await this._loadGroup();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async _loadGroup() {
      await this.$store.dispatch("auth/user");

      const groupResponse = await api.getGroup(this.$route.params.id);

      this.group = groupResponse.data;

      this.academicYearActive =
        this.group.attributes.academicYear.data.attributes.active;

      this.editedGroup = {
        name: this.group.attributes.name,
        subject: this.group.attributes.subject,
      };

      this.invitedMembers = sortByStringProperty(
        this.group.attributes.members.data.filter(
          (member) =>
            !member.attributes.active &&
            member.attributes.invited &&
            !member.attributes.deletedAt
        ),
        "attributes.user.data.attributes.lastName"
      );

      this.activeMembers = sortByStringProperty(
        this.group.attributes.members.data.filter(
          (member) =>
            member.attributes.active &&
            member.attributes.invited &&
            !member.attributes.deletedAt
        ),
        "attributes.user.data.attributes.lastName"
      );

      this.deletedMembers = sortByStringProperty(
        this.group.attributes.members.data.filter(
          (member) => member.attributes.deletedAt
        ),
        "attributes.user.data.attributes.lastName"
      );
    },

    async _loadCourses() {
      const courses = await api.getCourses();
      this.courses = courses.data;
    },

    formatDate(v) {
      return moment(v).utc().tz("Europe/Bratislava").format("DD.MM.YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-low {
  margin-bottom: -90px;
}
.outer-div-of-yellow-box {
  position: relative;
  bottom: -45px;
  .yellow-box {
    width: 400px;
    height: 94px;
    background: #fff5d1;
    border: 1px solid #e6d8a7;
    border-radius: 4px;

    .box-content {
      width: 95%;
    }

    .modal-box-text {
      color: #cab979;
      font-size: 14px;
    }
  }
}

.white {
  color: white !important;
}

.invitational-table {
  background-color: #0064ff;
}

button {
  font-size: small;
  font-weight: bold;
  &:hover {
    background-color: #fb2564;
    color: #ffffff !important;
    border-color: #fb2564 !important;
  }
}

.a-icon-wrapper {
  svg {
    max-height: 100% !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  font-size: 20px;
  display: inline-block;
  color: #2b2e4a;
}

p {
  font-size: 18px;
  line-height: 21px;
  //color: #000;
}

input {
  border: none;
  color: #2b2e4a;
  font-size: 24px;
  font-weight: 900;
  outline: none !important;
}

input.reactive {
  width: 5ch;
}

.edit-btn {
  font-family: Poppins;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;

  color: #2b2e4a !important;
  background: transparent !important;

  &:hover {
    color: #2b2e4a !important;
  }

  border: 1px solid #333333 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.cell-border {
  border-bottom: 1px solid #cadeea;
}

.font-small {
  font-size: 12px;
}
</style>
