import axios from "./axios";
import { injectCourseMetaFields } from "./helpers";
import { compact, upperFirst, deburr, get, sortBy } from "lodash";
import {
  CollectionTypeResponse,
  SingleTypeResponse,
  PartialCourse,
  HomePageResponse,
} from "./types";

type FeaturedType = "student" | "teacher";

function getFeaturedField(featuredType: FeaturedType) {
  return `featured${upperFirst(featuredType)}Courses` as
    | "featuredStudentCourses"
    | "featuredTeacherCourses";
}

const populateFields = [
  "thumbnail",
  "subjects.info",
  "topics.info",
  "subtitleLanguages.flag",
  "badges",
];

export const api = {
  courses: () => {
    return axios.get<CollectionTypeResponse<PartialCourse>>("courses", {
      params: {
        populate: populateFields,
        sort: ["publishedAt:desc"],
        pagination: {
          limit: 10,
        },
      },
    });
  },
  homePage: (featuredType: FeaturedType) => {
    const field = getFeaturedField(featuredType);

    const populateCourse = populateFields.map(
      (attribute) => `${field}.course.${attribute}`
    );
    const populatePath = populateFields.map(
      (attribute) => `${field}.path.${attribute}`
    );
    const populate = [...populateCourse, ...populatePath];

    return axios.get<SingleTypeResponse<HomePageResponse>>("home-page", {
      params: {
        fields: [field],
        populate,
        filters: {
          [field]: {
            course: {
              searchable: true,
            },
          },
        },
      },
    });
  },
};

export async function fetchHomePageData(featuredType: FeaturedType) {
  const [homePage, courses] = await Promise.all([
    api.homePage(featuredType),
    api.courses(),
  ]);

  const featuredField = getFeaturedField(featuredType);

  const featuredCoursesArray =
    homePage?.data?.attributes?.[featuredField] ?? [];

  const latestCourses =
    courses?.data?.map((course) => {
      injectCourseMetaFields(course, "course");
      return course;
    }) ?? [];

  const featuredCourses = compact(
    featuredCoursesArray.map((entry) => {
      const course = entry?.course?.data;
      const path = entry?.path?.data;

      if (!course && !path) {
        return null;
      }

      if (!course) {
        injectCourseMetaFields(path, "path");
        return path;
      }

      injectCourseMetaFields(course, "course");
      return course;
    })
  );

  return { featuredCourses, latestCourses };
}

export function sortByStringProperty(
  object: Record<any, unknown>,
  property: string
) {
  return sortBy(object, (item) => deburr(get(item, property)).toLowerCase());
}
